import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { withAuthentication } from "../../hoc/withAuthentication";
import Main from "../../components/main";
import ContentArea from "../../components/contentArea";
import FilterBar from "../../components/filterBar";
import PageSplit from "../../components/pageSplit";
import Button from "../../components/button";
import IconButton from "../../components/iconButton";
import Consts from "../../config/consts";
import {
    faClock,
    faArrowUp,
    faArrowDown,
    faPaintBrush,
    faGlobeEurope,
    faGamepad,
    faTheaterMasks,
    faMusic,
    faCameraRetro,
    faGlobe,
    faWrench,
    faQuidditch,
    faHeart,
} from "@fortawesome/free-solid-svg-icons";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED, Consts.AUTH_LEVELS.UNAUTHENTICATED], // INDEX MUST HAVE BOTH AUTH AND UNAUTH IN ORDER TO AVOID FAILURE LOOPING
        redirectPage: "/",
    },
    class Creators extends React.Component {
        // export default class Home extends React.Component {
        constructor(props) {
            super(props);

            // Grab the query params from the url
            const params = new URLSearchParams(props.location.search);

            // Grab out the genres param if any
            let selectedGenres = params.get("category");
            selectedGenres = !!selectedGenres ? selectedGenres.split(",") : [];

            // Grab out the genres param if any
            let selectedSortBy = params.get("sort") || "";

            this.state = {
                user: props.auth.user || null,

                items: [],
                hasRunInitialFetch: false,
                fetchQuantity: 40,
                fetchingMore: false,
                fetchMaxCount: 5,
                fetchCount: 0,
                fetchHasMore: true,
                fetchAllowInfinite: true,

                sortByOptions: [
                    //{ name:"Hot", icon:faFireAlt, selected:selectedSortBy==="hot" },
                    { name: "High Priced", icon: faArrowUp, selected: selectedSortBy === "pricehigh" },
                    { name: "Low Priced", icon: faArrowDown, selected: selectedSortBy === "pricelow" },
                    { name: "Liked", icon: faHeart, selected: selectedSortBy === "liked" },
                    { name: "Recent", icon: faClock, selected: selectedSortBy === "recentlyadded" },
                    //{ name:"Trending", icon:faChartLine, selected:selectedSortBy==="trending" },
                    //{ name:"Top Sellers", icon:faUser, selected:selectedSortBy==="topsellers" }
                ],
                categories: [
                    { name: "Art", icon: faPaintBrush, selected: selectedCategories.indexOf("art") > -1 },
                    { name: "Domain Names", icon: faGlobe, selected: selectedCategories.indexOf("domainnames") > -1 },
                    { name: "Games", icon: faGamepad, selected: selectedCategories.indexOf("games") > -1 },
                    { name: "Memes", icon: faTheaterMasks, selected: selectedCategories.indexOf("memes") > -1 },
                    { name: "Music", icon: faMusic, selected: selectedCategories.indexOf("music") > -1 },
                    { name: "Photography", icon: faCameraRetro, selected: selectedCategories.indexOf("photography") > -1 },
                    { name: "Sport", icon: faQuidditch, selected: selectedCategories.indexOf("sport") > -1 },
                    { name: "Utility", icon: faWrench, selected: selectedCategories.indexOf("utility") > -1 },
                    { name: "Metaverse", icon: faGlobeEurope, selected: selectedCategories.indexOf("metaverse") > -1 },
                ],
            };
        }

        componentDidMount() {
            // Run an initial get of some items
            setTimeout(() => {
                this.fetchMore();
            }, 100);
        }

        onFilterChange = (section, new_selected) => {
            /*

            Refetch a bunch of nfts here based on the new filter values
            and then push into the grid for display

        */
        };

        fetchMore = () => {
            return new Promise((resolve, reject) => {});
        };

        scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        };

        render() {
            return (
                <Main title={"BMinted"} auth={this.props.auth} prices={this.props.prices} providers={this.props.providers} currentChain={this.props.currentChain} chains={this.props.chains}>
                    <ContentArea extraTopPadding={true} header={"Creators"}></ContentArea>
                </Main>
            );
        }
    }
);
